<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="about_client" service="refMortgage" backlink="/profile" />
      <div class="content illustration illustration_form">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "s3p7_text_1" | localize }}</p>

          <form @submit.prevent="submitHandler">
            <div v-for="(acc, k) in accounts" :key="k">
              <div class="promo__container" v-if="clientCount > 1">
                <p class="promo promo_blocks2">{{ "account" | localize }} {{ k + 1 }}</p>
                <button
                  type="button"
                  v-if="accounts.length > 1"
                  class="delete"
                  @click="deleteAccount(k)"
                ></button>
              </div>
              <div :class="'scroll_to_' + k"></div>

              <div class="form__group">
                <v-autocomplete
                  v-model="acc.bank"
                  :items="banks"
                  @blur="check(k, 'bank')"
                  :class="{ invalid: checks[k].bank && !acc.bank }"
                  item-text="name"
                  item-value="value"
                  cache-items
                  :label="'s3p7_label_1' | localize"
                  :placeholder="'s3p7_placeholder_1' | localize"
                  append-icon="icon-arrow-down"
                  outlined
                ></v-autocomplete>

                <v-select
                  v-model.lazy="acc.status"
                  @blur="check(k, 'status')"
                  :class="{ invalid: checks[k].status && !acc.status }"
                  :items="getList('s3p7_select_1_value_', 3)"
                  item-text="name"
                  item-value="value"
                  :label="'s3p7_label_2' | localize"
                  :placeholder="'s3p7_placeholder_2' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  outlined
                ></v-select>
              </div>

              <div class="form__group">
                <v-select
                  v-if="members > 1"
                  v-model.lazy="acc.client"
                  @blur="check(k, 'client')"
                  :class="{ invalid: checks[k].client && !acc.client }"
                  :items="clients"
                  item-text="name"
                  item-value="value"
                  :label="'s3p7_label_3' | localize"
                  :placeholder="'select' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  outlined
                ></v-select>
              </div>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="submit"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
            >
              {{ "form_continue" | localize }}
            </button>

            <button
              type="button"
              class="button button__form button__form_empty"
              @click="addAccount(scheme)"
            >
              + {{ "form_add_account" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Refinance_mortgage_banks',
  components: { Backlink },
  mixins: [clientMixin, commonMixin],
  data: () => ({
    scheme: { bank: null, status: null }
  }),
  created () { 
    // Установка количества шагов для услуги
    this.setAllSteps('refMortgage', 27)

    const refMortgage = this.$cookies.get('refMortgage') || {}

    // Получатели и данные
    this.members = refMortgage.saved_clients ? refMortgage.saved_clients.length : 1
    this.clients = refMortgage.saved_clients || []
    this.accounts = refMortgage.accounts || []
    if (this.members > 1) this.scheme.client = null

    this.prepareScheme(this.scheme)
    this.success = !this.checkError()
  },
  computed: { loaded_banks () { return this.$store.state.banks} },
  watch: { loaded_banks () { this.setBanks() } },
  methods: {
    submitHandler () {
      const errorKey = this.checkError(true)
      if (errorKey !== false) {
        this.$scrollTo('.scroll_to_' + errorKey, 300, { offset: -150 })
        this.success = false
        return
      }

      this.success = true
      const refMortgage = this.$cookies.get('refMortgage') || {}
      refMortgage.accounts = this.accounts
      this.$cookies.set('refMortgage', refMortgage)
      this.saveClientData(
        'client_bills',
        '/refinance-mortgage/income',
        'refMortgage'
      )
    }
  }
}
</script>
